<template>
  <div class="app page-bg">
    <esports-header-authen />
    <div
      class="container register-page login-page pt-4"
      :class="[{ 'verify-code-page': isVerifyCode }]"
    >
      <b-row class="justify-content-center w-100">
        <component
          @activeComponent="activeComponent"
          :formRegisterData="formRegisterData"
          :is="currentTabComponent"
        ></component>
      </b-row>
    </div>
    <esports-footer-authen />
  </div>
</template>

<script>
import LoginForm from "./Login/LoginForm";
import NotifyVerifyUser from "./Register/NotifyVerifyUser";
import RegisterEnterUserNameSocial from "./Register/RegisterEnterUserNameSocial";

export default {
  name: "LoginPage",
  title: "login",
  components: {
    LoginForm,
    RegisterEnterUserNameSocial,
    NotifyVerifyUser,
  },
  data() {
    return {
      isSubmitted: false,
      formRegisterData: null,
      currentTabComponent: LoginForm,
      isVerifyCode: false
    };
  },
  methods: {
    activeComponent(payload) {
      this.formRegisterData = payload.user;
      this.currentTabComponent = payload.name;

      if (payload.name === "RegisterEnterUserNameSocial") {
        this.isVerifyCode = true;
        return;
      }
      this.isVerifyCode = false;
    },
  },
};
</script>